<template>
  <div class="commonTabbr">
    <div
      class="back-home"
      ref="moveBtn"
      @mousedown="down"
      @touchstart="down"
      @mousemove="move"
      @touchmove="move"
      @mouseup="end"
      @touchend="end"
      @touchcancel="end"
    >
      <div class="tabberImg" @click="handleTarClick">
        <img v-if="!isShow" src="../assets/images/tab/active.png" alt="" />
        <img v-if="isShow" src="../assets/images/tab/close.png" alt="" />
      </div>
      <transition name="van-slide-right">
        <div v-show="isShow" class="flex">
          <!-- <div v-show="isShow" ref="moveTabber" class="showTabber"> -->

          <!-- <router-link  @click="homeClick"> -->
          <div class="tabberImg" @click="homeClick">
            <img src="../assets/images/tab/home.png" alt="" />
          </div>
          <!-- </router-link> -->
          <!-- <router-link  @click="userClick"> -->
          <div class="tabberImg" @click="userClick">
            <img src="../assets/images/tab/user-o.png" alt="" />
          </div>
          <!-- </router-link> -->
          <div class="tabberImg" @click="onClickShop">
            <img src="../assets/images/tab/shop.png" alt="" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flags: false,
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      // yTabber: "",
      isShow: false,
      moveBtn: {},
      // moveTabber: {},
    };
  },
  created() {
    // wxInit();
  },
  mounted() {
    this.moveBtn = this.$refs.moveBtn;
    // this.moveTabber = this.$refs.moveTabber;
    this.moveBtn.style.top = "60px";
  },
  methods: {
    handleTarClick() {
      this.isShow = !this.isShow;
    },
    homeClick() {
      this.$router.push("/home");
      this.isShow = !this.isShow;
    },
    // groupClick() {
    //   this.$router.push("group");
    //   this.isShow = !this.isShow;
    // },
    // cartClick() {
    //   this.$router.push("cart");
    //   this.isShow = !this.isShow;
    // },
    userClick() {
      this.$router.push("/user");
      this.isShow = !this.isShow;
    },
    // 跳转到在线商城
    onClickShop() {
      let userInfoQuery = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userInfoQuery, userInfoQuery.companyApi, "userInfoQuery");
      location.href = userInfoQuery.companyApi;
    },
    // 实现移动端拖拽
    down(event) {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      // 点击的位置坐标位置
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.moveBtn.offsetLeft;
      this.dy = this.moveBtn.offsetTop;
    },
    move(event) {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        // console.log(event.touches,touch.clientX,120)
        // 移动的距离
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        this.yTabber = this.dy + this.ny;
        // 获取屏幕宽高
        var clientWidth = document.documentElement.clientWidth;
        var clientHeight = document.documentElement.clientHeight;
        if (
          this.xPum > 0 &&
          this.xPum < clientWidth - this.moveBtn.offsetWidth
        ) {
          this.moveBtn.style.left = this.xPum + "px";
        }
        if (
          this.yPum > 0 &&
          this.yPum < clientHeight - this.moveBtn.offsetHeight
        ) {
          this.moveBtn.style.top = this.yPum + "px";
          // console.log(this.yTabber > 0,this.yTabber < clientHeight - 250)
          // if (clientHeight < this.yPum + 250) {
          //   this.moveTabber.style.position = "fixed";
          //   this.moveTabber.style.top = this.yPum -250 + "px";
          //   console.log(
          //     this.moveTabber.style.top,
          //     this.moveBtn.style.left,
          //     148
          //   );
          //   console.log(this.moveBtn.style.top, this.yPum, 135);
          // } else {
          //   this.moveTabber.style.position = "static";
          //   // this.moveTabber.style.top = this.yPum +240 + "px";
          // }
        }

        //阻止页面的滑动默认事件
        document.addEventListener("touchmove", this.handler, {
          passive: false,
        });
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
      document.addEventListener("touchmove", this.handler, {
        passive: false,
      });
    },
    handler(event) {
      if (this.flags) {
        event.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.commonTabbr {
  position: fixed;
  z-index: 99999;
  a {
    display: inline-block;
  }
  // position: relative;
  .back-home {
    position: fixed;
    z-index: 99999;
    padding-bottom: 15vw;
    display: flex;
    flex-wrap: wrap;
  }
  .flex {
    flex-wrap: wrap;
  }
  .tabberImg {
    width: 48px;
    height: 48px;
  }
  img {
    width: 48px;
    height: 48px;
  }
  .van-icon {
    font-size: 28px;
  }
}
</style>
