import { createRouter, createWebHashHistory } from "vue-router";
import { getRouterStore } from "../store/index";

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
      meta: {
        index: 1,
        title: "积分中心",
        keepAlive: false,
      },
    },
     // win
    {
      path: "/redeemList",
      name: "redeemList",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/redeemList.vue"),
      meta: {
        index: 1,
        title: "积分兑换",
        keepAlive: false,
      },
    },
     {
      path: "/checkout",
      name: "checkout",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/checkout.vue"),
      meta: {
        index: 1,
        title: "待兑换",
        keepAlive: false,
      },
    },
    {
      path: "/shopDetail",
      name: "shopDetail",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/shopDetail.vue"),
      meta: {
        index: 1,
        title: "商品详情",
        keepAlive: false,
      },
    },
    {
      path: "/redeemed",
      name: "redeemed",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/redeemed.vue"),
      meta: {
        index: 1,
        title: "待兑换",
        keepAlive: false,
      },
    },
   
    {
      path: "/exChange",
      name: "exChange",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/exChange.vue"),
      meta: {
        index: 1,
        title: "兑换结果",
        keepAlive: false,
      },
    },
    {
      path: "/declare",
      name: "declare",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/declare.vue"),
      meta: {
        index: 1,
        title: "积分问答",
        keepAlive: false,
      },
    },
    {
      path: "/question",
      name: "question",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/questionDetail.vue"),
      meta: {
        index: 1,
        title: "积分问答",
        keepAlive: false,
      },
    },
    {
      path: "/statement",
      name: "statement",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/statement.vue"),
      meta: {
        index: 1,
        title: "积分明细",
        keepAlive: false,
      },
    },
    {
      path: "/chooseAdress",
      name: "chooseAdress",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/chooseAdress.vue"),
      meta: {
        index: 1,
        title: "选择收货地址",
        keepAlive: false,
      },
    },
    {
      path: "/redirect",
      name: "redirect",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/redirect.vue"),
      meta: {
        index: 1,
        title: "登录授权页",
        keepAlive: false,
      },
      
    },
    // {
    //   path: "/scroll",
    //   name: "scroll",
    //   component: () =>
    //     import(/* webpackChunkName: "home" */ "@/views/scroll.vue"),
    //   meta: {
    //     index: 1,
    //     title: "滚动",
    //     keepAlive: false,
    //   },
    // },

    // {
    //   path: "/login",
    //   name: "login",
    //   component: () =>
    //     import(/* webpackChunkName: "login" */ "@/views/Login1.vue"),
    //   meta: {},
    // },
    // {
    //   path: '/redirect',
    //   name: 'redirect',
    //   component: () => import(/* webpackChunkName: "login" */ '@/views/redirect.vue'),
    //   meta: {
    //     index: 1
    //   }
    // },
    {
      path: "/luckDraw",
      name: "luckDraw",
      component: () =>
        import(/* webpackChunkName: "category" */ "@/views/luckDraw.vue"),
      meta: {
        index: 1,
        title: "积分抽奖",
        keepAlive: false,
      },
    },
    {
      path: "/prizeDetail",
      name: "prizeDetail",
      component: () =>
        import(/* webpackChunkName: "home" */ "@/views/prizeDetail.vue"),
      meta: {
        index: 1,
        title: "奖品详情",
        keepAlive: false,
      },
    },
    {
      path: "/lottery",
      name: "lottery",
      component: () =>
        import("@/views/lottery.vue"),
      meta: {
        index: 1,
        title: "抽奖说明",
        keepAlive: false,
      },
    },
    {
      path: "/user",
      name: "user",
      component: () =>
        import(/* webpackChunkName: "user" */ "@/views/User.vue"),
      meta: {
        index: 1,
        title: "我的",
        keepAlive: false,
      },
    },
    {
      path: "/adress",
      name: "adress",
      component: () =>
        import(/* webpackChunkName: "address" */ "@/views/adress.vue"),
      meta: {
        index: 2,
        title: "我的地址",
        keepAlive: false,
      },
    },
    {
      path: "/harvest",
      name: "harvest",
      component: () =>
        import(/* webpackChunkName: "address" */ "@/views/harvest.vue"),
      meta: {
        index: 2,
        title: "收货地址",
        keepAlive: false,
      },
    },
    {
      path: "/mineDraw",
      name: "mineDraw",
      component: () =>
        import(/* webpackChunkName: "address" */ "@/views/mineDraw.vue"),
      meta: {
        index: 2,
        title: "我的抽奖",
        keepAlive: false,
      },
    },
    // {
    //   path: "/order",
    //   name: "order",
    //   component: () =>
    //     import(/* webpackChunkName: "address" */ "@/views/Order.vue"),
    //   meta: {
    //     index: 2,
    //     title: "我的订单",
    //     keepAlive: false,
    //   },
    // },
    {
      path: "/order",
      name: "order",
      component: () =>
        import(/* webpackChunkName: "address" */ "@/views/orderlist.vue"),
      meta: {
        index: 2,
        title: "我的订单",
        keepAlive: false,
      },
    },
    {
      path: "/orderDetail",
      name: "orderDetail",
      component: () =>
        import(/* webpackChunkName: "address" */ "@/views/orderDetail.vue"),
      meta: {
        index: 2,
        title: "订单详情",
        keepAlive: false,
      },
    },
    {
      path: "/error",
      name: "error",
      component: () =>
        import(/* webpackChunkName: "address" */ "@/views/error.vue"),
      meta: {
        index: 2,
        title: "404",
        keepAlive: false,
      },
    },
  ],
  // 返回页面还在原来位置
  scrollBehavior(to, from, savedPosition) {
    return { top: from.meta.scrollTop, left: 0 };
  },
});

// router.afterEach((to, from) => {
//   let bodyScrollTop = document.body.scrollTop;
//   if (bodyScrollTop !== 0) {
//     document.body.scrollTop = 0;
//     return;
//   }
//   let docScrollTop = document.documentElement.scrollTop;
//   if (docScrollTop !== 0) {
//     document.documentElement.scrollTop = 0;
//   }
// });

router.beforeEach(async (to, from) => {
  to.meta.scrollTop = document.documentElement.scrollTop
    ? document.documentElement.scrollTop
    : document.body.scrollTop;
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});
// router.beforeEach((to, from, next) => {
//   console.log(to);
//   if (to.fullPath === "/home") {
//     console.log(sessionStorage.getItem("position"));
//     //获取存储位置

//     window.scrollTo(0, sessionStorage.getItem("position"));
//   }
//   if (from.fullPath === "/home") {
//     console.log(sessionStorage.getItem("position"));
//     //获取存储位置

//     window.scrollTo(0, sessionStorage.getItem("position"));
//   }
//   next();
// });

export default router;
