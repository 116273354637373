<template>
  <div id="app">
    <!--keep-alive 组件缓存 当路由keepAlive为true的时候 可以做返回页面还在原来的位置  -->
    <!-- <router-view class="router-view"
                 v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component"
                   :key="$route.name" />
      </transition>
    </router-view> -->
    <commonTabbar></commonTabbar>
    <router-view class="router-view" v-slot="{ Component }">
      <!-- <keep-alive> -->
      <component :is="Component" :key="$route.name" />
      <!-- </keep-alive> -->
      <!-- <component :is="Component"
                 :key="$route.name"
                 v-if="!$route.meta.keepAlive" /> -->
    </router-view>
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
// import { wxInit } from "./utils/wxHelper";
import commonTabbar from "./components/commonTabbar";

export default {
  components: {
    commonTabbar,
  },
  setup() {
    let router = useRouter();
    let route = useRoute();
    let routerTag = ref(false);
    const state = reactive({
      transitionName: "slide-left",
    });
    router.beforeEach((to, from) => {
      document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        window.scroll(0, 0);
      if (to.meta.index > from.meta.index) {
        state.transitionName = "slide-left"; // 向左滑动
      } else if (to.meta.index < from.meta.index) {
        // 由次级到主级
        state.transitionName = "slide-right";
      } else {
        state.transitionName = ""; // 同级无过渡效果
      }
    });
    // document.body.addEventListener('touchmove', function (e) {
    //   e.preventDefault()
    // }, { passive: false })
    onMounted(() => {
      // wxInit();
      // routerTag.value = route.meta.keepAlive
      // console.log(route, '1');
      // console.log(route.meta.keepAlive, '1');
      // console.log(route.meta.value.keepAlive, '1');
      // console.log(router, '2');
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less">
// html,
// body {
//   height: 100vh;
//   // overflow-x: hidden;
//   // overflow-y: scroll;
//   // overflow: hidden;
// }
// body:before {
//   width: 100%;
//   height: 100%;
//   content: '';
//   position: fixed;
//   z-index: -1;
//   top: 0;
//   left: 0;
//   background: #fff;
// }
#app {
  height: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #333;
  font-size: 14px;
}
//  position: absolute 影响布局
.router-view {
  width: 100%;
  height: auto;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  height: 100%;
  will-change: transform;
  transition: all 500ms;
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.van-badge--fixed {
  z-index: 1000;
}
</style>
